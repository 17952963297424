import Logo from './logo.png';
import ApexLogo from './apex-logo.png';
import React, { Component } from "react";
import axios from "axios";
import { Navigate } from 'react-router-dom';

export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginStatus: 0,
            redirect: false
        };
        this.usernameRef = React.createRef();
        this.passwordRef = React.createRef();
    }

    authenticate = event => {
        event.preventDefault(); // Prevent form submission
        const username = this.usernameRef.current.value;
        const password = this.passwordRef.current.value;
        const userObj = { username, password };

        if (username && password) {
            axios.post(`auth/token`, userObj)
                .then(response => {
                    this.setState({ loginStatus: 1, redirect: true });
                    localStorage.setItem('token', response.data.access_token);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                    this.setState({ loginStatus: 2 });
                });
        }
    };

    render() {
        const { loginStatus, redirect } = this.state;
        let authStatus;

        if (loginStatus === 1) {
            authStatus = <p style={{ color: 'green' }}>Authenticated</p>;
        } else if (loginStatus === 2) {
            authStatus = <p style={{ color: '#f00' }}>Incorrect Username/Password</p>;
        }

        if (redirect) {
            return <Navigate to="/suppliers-data" />;
        } else {
            return (
                <>
                    <header className="navigation fixed-top">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg navbar-white">
                                <a className="navbar-brand order-1" href="/">
                                    {/*<img className="img-fluid" width="100px" src={Logo} alt="Peak" />*/}
                                    <img className="img-fluid" width="100px" src={ApexLogo} alt="Peak" />
                                </a>
                                <div className="collapse navbar-collapse text-center order-lg-2 order-3" id="navigation"></div>
                                <div className="order-2 order-lg-3 d-flex align-items-center"></div>
                            </nav>
                        </div>
                    </header>

                    <section className="section-sm" style={{ marginTop: '120px' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 mx-auto">
                                    <div className="content mb-5">
                                        <h2 id="we-would-love-to-hear-from-you" style={{fontSize:'20px'}}>Login</h2>
                                        <p style={{ borderBottom: '2px #05aced solid' }}></p>
                                    </div>

                                    <form onSubmit={this.authenticate}>
                                        <div className="form-group">
                                            <label htmlFor="username">Email</label>
                                            <input
                                                type="email"
                                                name="username"
                                                ref={this.usernameRef}
                                                id="username"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Password</label>
                                            <input
                                                type="password"
                                                name="password"
                                                ref={this.passwordRef}
                                                id="password"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <a href={'/forgot-password'} style={{ color: '#05aced', fontWeight: 'bold' }}>Forgot Password</a>
                                        </div>
                                        <div className="form-group">
                                            {authStatus}
                                        </div>
                                        <button type="submit" className="btn btn-primary">Login</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            );
        }
    }
}

export default Login;

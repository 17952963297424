import React, {Component, useEffect, useState} from 'react';
import axios from "axios";
import {Routes, Route, useParams, Navigate} from 'react-router-dom';
import "yet-another-react-lightbox/styles.css";
import Header from "./Components/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Tab, Tabs} from "react-bootstrap";
import PhoneNumberComponent from "./Components/PhoneNumberComponent";
import QuoteHeaders from "./Data/quotes.json";
import { Button, Modal, Form } from "react-bootstrap";

const ViewRecord = () => {

    const [supplierData, setSupplierData] = useState(0);
    const [redirect, setRedirect] = React.useState(false);
    const [inputValue, setInputValue] = useState('');
    const [innerRecord, setInnerRecord] = useState([]);
    const [innerContact, setInnerContact] = useState([]);
    const [innerComm, setInnerComm] = useState([]);
    const [innerSupplierData, setInnerSupplierData] = useState([]);
    const [emailSync, setEmailSync] = useState(false);
    const [peakMail, setPeakMail] = useState([]);
    const [show, setShow] = useState(false);
    const [newEmailshow, setNewEmailshow] = useState(false);
    const [popupData, setPopupData] = useState(0);
    const [contact, setContact] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    
    let { recordId } = useParams();
    let { dataId } = useParams();
    useEffect(() => {
        console.log(innerSupplierData)
        console.log(innerContact)
        
        
        
        axios.get(`get-supplier-data/${recordId}`)
            .then(response => {
                const supplier = response.data.supplier;
                const unauthorized = response.data.unauthorized;
                if(unauthorized === true)
                {
                    setRedirect(unauthorized)
                }
                setSupplierData(supplier)
                console.log(supplier);
                setInputValue(supplier.PeakItem);
            })

        axios.get(`get-supplier-inner-data/${dataId}`)
            .then(response => {
                const itemData = response.data.itemData;
                const contacts = response.data.contacts;
                const communications = response.data.communications;
                const supplier = response.data.supplier;
                const emailSync = response.data.emailSyncEnabled;
                const peakMail = response.data.peakMail;
                
                
                setInnerRecord(itemData)
                
                setInnerContact(contacts)
                
                setInnerComm(communications)
                
                setInnerSupplierData(supplier)
                
                setEmailSync(emailSync)
                
                setPeakMail(peakMail)
                
            })
    }, []);

    const formatDateColor = (dateString)=> {
        if(dateString === undefined) {
            return '#000';
        }
        const [year, month, day] = dateString.split('-');
        const inputDate = new Date(year, month - 1, day, 0,0, 0);

        if (!isNaN(inputDate.getTime())) {
            const formattedDate = inputDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            if(new Date() > inputDate)
            {
                return '#f00'
            }
            else
            {
                return '#000';
            }

        }
    };

    const getFormattedDate = (dateString) => {
        if (dateString !== undefined && dateString.trim() !== '') {
            const [year, month, day] = dateString.split('-');
            const inputDate = new Date(year, month - 1, day, 0,0, 0);

            if (!isNaN(inputDate.getTime())) {
                const formattedDate = inputDate.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                });
                return formattedDate;
            } else {
                return "Invalid Date";
            }
        } else {
            return "Invalid Input";
        }

    };

    const formatDateTime = (dateString) => {
        if(dateString !== undefined)
        {
            const myDate = new Date(dateString);

            return  myDate.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });

        }
    };

    const formatEmailDateTime = (dateStr) => {
        if(dateStr !== undefined)
        {
            const dateObj = new Date(dateStr);
            const today = new Date();

            const isToday = dateObj.toDateString() === today.toDateString();
            const isCurrentYear = dateObj.getFullYear() === today.getFullYear();

            if (isToday) {
                return dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
            } else {
                const options = isCurrentYear ? { day: 'numeric', month: 'short' } : { day: 'numeric', month: 'short', year: 'numeric' };
                return dateObj.toLocaleDateString('en-US', options);
            }

        }
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const onSaveClick = (e) => {
        console.log(inputValue)
        let dataObj = {
            peakItemNumber:  inputValue,
            Id: recordId
        }
        console.log(dataObj)
        axios.put(`update-supplier-peak`, dataObj)
            .then(response => {
                toast.success('Data saved Successfully!');
            })
    };
    
    const onNewEmailClick = (e) => {
        setNewEmailshow(true)
    };
    
    const onEmailSyncClick = (e) => {
        let dataObj = {
            recordId:  recordId,
            dataId: dataId
        }
        
        axios.put(`peakmail/token-generate`, dataObj)
            .then(response => {
                if(response.data != null && response.data.auth_url != null){
                    window.location.href = response.data.auth_url;
                }
            })
    };
    
    const getQuoteContent = (keyData) => {
        let content = [];
        for (let i = 0; i < innerRecord.length; i++) {
            let item = innerRecord[i];
            if(keyData === 'GoodThruDate') {
                content.push(<td>{getFormattedDate(item[keyData])}</td>);
            } else if(keyData === 'dateTimeCreated') {
                content.push(<td>{formatDateTime(item[keyData])}</td>);
            } else {
                content.push(<td>{item[keyData]}</td>);
            }

        }
        return content;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle the form submission here, such as sending the data to an API
        console.log('Contact:', contact);
        console.log('Subject:', subject);
        console.log('Message:', message);

        // You can reset form fields here if needed
        setContact('');
        setSubject('');
        setMessage('');

        let emailObj = {
            To:  contact,
            Subject: subject,
            Message: message
        }
        
        axios.put(`peakmail/send-email`, emailObj)
            .then(response => {
                // Close the modal after submission
                handleNewEmailClose();
                toast.success('Email Sent!');
            })
            .catch(error => {
                console.error('There was an error!', error);
            });

    };
    
    const handleNewEmailClose = () => setNewEmailshow(false);
    const handleClose = () => setShow(false);
    const handleShow = (p) => {
        setShow(true);
        setPopupData(p)
    };


    if(redirect)
    {
        return (<Navigate to="/suppliers-data"/>)
    }
    else {
        return (
            <>
                <Header/>


                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { popupData && (<div>
                            <p><strong>From Name:</strong> {popupData.FromName}</p>
                            <p><strong>From Email:</strong> {popupData.FromEmail}</p>
                            <p><strong>Subject:</strong> {popupData.Subject}</p>
                            <p><strong>Message</strong></p>
                            <p>{popupData.Message}</p>
                        </div>)}


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={newEmailshow} onHide={handleNewEmailClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>New Email</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formContact">
                                <Form.Label>Contact</Form.Label>
                                <Form.Select
                                    className={'form-control'}
                                    value={contact}
                                    onChange={(e) => setContact(e.target.value)}
                                    required
                                >
                                    <option value="">Select a contact</option>
                                    {innerContact.map((item) => (
                                        <option key={item.ContactId} value={item.Email}>
                                            {item.Name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formSubject">
                                <Form.Label>Subject</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter subject"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formMessage">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter your message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Button variant="success" type="submit">
                                Send
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                <section className="section pb-0" style={{marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12" style={{marginTop:'20px'}}>
                                <h2 className="h5 ">
                                    <a className="" href="/suppliers-data">
                                        <i className="ti-angle-left"></i> Back
                                    </a>

                                </h2>
                                <div>
                                    <div id={'tabs-page'}>
                                        <Tabs defaultActiveKey="current" id="uncontrolled-tab-example">
                                            <Tab eventKey="current" title="Current">
                                                <div>
                                                    <article className="card">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-12 mb-12">
                                                                    <div className="widget widget-categories">
                                                                        <div className="row">
                                                                            <div className="col-lg-12 mb-12">
                                                                                <ul className="list-unstyled widget-list">
                                                                                    <li>
                                                                    <span className="d-flex"> <span className="suphead">Apex Item #  </span><span
                                                                        className="supseperator">:</span>
                                                                        <span className="supbody">
                                                                            <div className={'row'}>
                                                                                <div className={'col-12 row'}>
                                                                                    <div className={'col-6'}>
                                                                                        <input type="text"
                                                                                               className="form-control"
                                                                                               id="usr"
                                                                                               value={inputValue}
                                                                                               onChange={handleInputChange}/>
                                                                                    </div>
                                                                                    <div className={'col-6'}>
                                                                                        <button type="button"
                                                                                                onClick={onSaveClick}
                                                                                                className="btn btn-success">Save</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                            
                                                                        </span>
                                                                    </span>
                                                                                    </li>
                                                                                    <li>
                                                                        <span className="d-flex">
                                                                            <span
                                                                                className="suphead">Supplier Name</span>
                                                                            <span className="supseperator">:</span>
                                                                            <span
                                                                                className="supbody">{supplierData.SupplierName}</span>
                                                                        </span>
                                                                                    </li>
                                                                                    <li>
                                                                    <span className="d-flex"> <span className="suphead">Supplier Item #  </span><span
                                                                        className="supseperator">:</span><span
                                                                        className="supbody">{supplierData.SupplierItem}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">Item Description  </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.ItemDescription}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">MOQ  </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.MOQ}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">Pack Size  </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.PackSize}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">U/M  </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.UM}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">Tier Qty 1 </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.TierQty1}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">$ Cost  </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.TierQty1Cost}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">Tier Qty 2  </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.TierQty2}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">$ Cost  </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.TierQty2Cost}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">Tier Qty 3  </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.TierQty3}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">$ Cost  </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.TierQty3Cost}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">Lead Time (days)  </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.LeadTime}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">Payment Terms </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.PaymentTerms}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">$ Credit Line </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.CreditLine}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">Item Category </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.ItemCategory}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">Good Thru Date </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">
                                                                            <span
                                                                                style={{'color': formatDateColor(supplierData.GoodThruDate)}}>
                                                                            {getFormattedDate(supplierData.GoodThruDate)}
                                                                            </span>
                                                                        </span>
                                                                        </span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">FOB Origin </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.FOBOrigin}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">FOB Destination </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.FOBDestination}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">Main Contact  </span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.MainContact}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">Cell Ph #</span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.CellPh}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">Office PH #</span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.OfficePh}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">Shipping Address</span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{supplierData.ShippingAddress}</span></span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="d-flex"> <span
                                                                                            className="suphead">Created</span><span
                                                                                            className="supseperator">:</span><span
                                                                                            className="supbody">{formatDateTime(supplierData.dateTimeCreated)}</span></span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </article>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="history" title="History">
                                                <div className="row">
                                                    <div className="col-lg-12 mb-12" style={{overflow: "scroll"}}>
                                                        <table className="table table-bordered">
                                                            <tbody>
                                                            {innerRecord[0] && QuoteHeaders.map((quote, quoteIndex) => (

                                                                <tr>
                                                                    <td className={'headerStringHistory'}>{quote.header}</td>
                                                                    {getQuoteContent(quote.key)}

                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="info" title="Info">
                                                <div>
                                                    <article className="card">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                {(innerSupplierData && innerContact[0]) ?
                                                                    <React.Fragment>
                                                                        <div className="col-lg-6 mb-12">
                                                                            <ul className="list-unstyled widget-list">
                                                                                <li><a href="#" className="d-flex">Payment
                                                                                    Terms <span
                                                                                        className="ml-auto">{innerSupplierData['PaymentTerms']}</span></a>
                                                                                </li>
                                                                                <li><a href="#" className="d-flex">$
                                                                                    Credit
                                                                                    Line <span
                                                                                        className="ml-auto">{innerSupplierData['CreditLine']}</span></a>
                                                                                </li>
                                                                                <li><a href="#" className="d-flex">Shipping
                                                                                    Address <span
                                                                                        className="ml-auto">{innerSupplierData['ShippingAddress']}</span></a>
                                                                                </li>
                                                                                <li><a href="#" className="d-flex">FOB
                                                                                    Origin <span
                                                                                        className="ml-auto">{innerSupplierData['fobOrigin']}</span></a>
                                                                                </li>

                                                                            </ul>
                                                                        </div>
                                                                        <div className="col-lg-6 mb-12">
                                                                            <ul className="list-unstyled widget-list">
                                                                                <li><a href="#" className="d-flex">Main
                                                                                    Contact <span
                                                                                        className="ml-auto">{innerContact[0]['Name']}</span></a>
                                                                                </li>
                                                                                <li><a href="#" className="d-flex">Cell
                                                                                    Ph
                                                                                    # <span
                                                                                        className="ml-auto">{innerContact[0]['CellPhone']}</span></a>
                                                                                </li>
                                                                                <li><a href="#" className="d-flex">Office
                                                                                    PH
                                                                                    # <span
                                                                                        className="ml-auto">{innerContact[0]['OfficePhone']}</span></a>
                                                                                </li>
                                                                                <li><a href="#" className="d-flex">FOB
                                                                                    Destination <span
                                                                                        className="ml-auto">{innerSupplierData['fobDest']}</span></a>
                                                                                </li>


                                                                            </ul>
                                                                        </div>
                                                                    </React.Fragment> : ''
                                                                }


                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="contact" title="Contact">
                                                <div className={'row'}>
                                                    <div className={'col-lg-6 mb-6'} style={{'marginTop':'15px'}}>
                                                        <table className="table table-bordered">
                                                            <thead className="tableHeadTabbed" style={{fontSize:'13px'}}>
                                                            <tr>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Email</th>
                                                                <th scope="col">CellPhone</th>
                                                                <th scope="col">OfficePhone</th>
                                                                <th scope="col">Created</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody style={{fontSize:'13px'}}>
                                                            {innerContact[0] && innerContact.map((p, index) => (
                                                                <React.Fragment key={index}>
                                                                    <tr>
                                                                        <td>{p.Name}</td>
                                                                        <td>{p.Email}</td>
                                                                        <td><PhoneNumberComponent
                                                                            phoneNumber={p.CellPhone}/></td>
                                                                        <td><PhoneNumberComponent
                                                                            phoneNumber={p.OfficePhone}/></td>
                                                                        <td>{formatDateTime(p.DateTimeCreated)}</td>
                                                                    </tr>
                                                                </React.Fragment>))}

                                                            {innerContact.length === 0 && (
                                                                <React.Fragment>
                                                                    <tr>
                                                                        <td colSpan={5}>No Contacts</td>
                                                                    </tr>
                                                                </React.Fragment>)}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className={'col-lg-6 mb-6'}></div>

                                                </div>
                                            </Tab>
                                            <Tab eventKey="communication" title="Communication">
                                                <div className={'row'}>
                                                    <div className={'col-lg-10 mb-10'} style={{'marginTop': '15px'}}>
                                                        {
                                                            emailSync &&
                                                            <button type="button" onClick={onEmailSyncClick}
                                                                    className="btn btn-success"
                                                                    style={{marginBottom: '8px'}}>Email
                                                                Sync</button>
                                                        }
                                                        <button type="button" onClick={onNewEmailClick}
                                                                className="btn btn-warning"
                                                                style={{marginBottom: '8px'}}>New Email
                                                        </button>
                                                        <table className="table table-bordered">
                                                            <thead className="tableHeadTabbed"
                                                                   style={{fontSize: '13px'}}>
                                                            <tr>
                                                                <th scope="col" width={250}>Name</th>
                                                                <th scope="col">Message</th>
                                                                <th scope="col" width={110}>Created</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody style={{fontSize: '13px'}}>
                                                            {peakMail[0] && peakMail.map((p, index) => (
                                                                <React.Fragment key={index}>
                                                                    <tr>
                                                                        <td><p className={'pointer'}
                                                                               onClick={() => handleShow(p)}>{p.FromName}</p>
                                                                        </td>
                                                                        <td><p className={'pointer'}
                                                                               onClick={() => handleShow(p)}>{p.MessageSnippet}</p>
                                                                        </td>
                                                                        <td><p className={'pointer'}
                                                                               onClick={() => handleShow(p)}>{formatEmailDateTime(p.DatetimeCreated)}</p>
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>))}
                                                            {peakMail.length === 0 && (
                                                                <React.Fragment>
                                                                    <tr>
                                                                        <td colSpan={3}>No Communications</td>
                                                                    </tr>
                                                                </React.Fragment>)}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className={'col-lg-2 mb-2'}></div>

                                                </div>
                                            </Tab>
                                            <Tab eventKey="system-notifications" title="System Notifications">
                                                <div style={{'marginTop': '15px'}}>
                                                    <table className="table table-bordered">
                                                        <thead className="tableHeadTabbed">
                                                        <tr>
                                                            <th scope="col">Message</th>
                                                            <th scope="col">Created</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>

                                                        {innerComm.map((p, index) => (
                                                            <React.Fragment key={index}>
                                                                <tr>
                                                                    <td>{p.Type === 10 ?
                                                                        <p>This is a friendly reminder that it is time
                                                                            to update pricing on this
                                                                            item.</p> : p.Type === 5 ?
                                                                            <p>This is a second friendly reminder that
                                                                                it is time to update pricing
                                                                                on this item.</p> :
                                                                            <p>This is your final friendly reminder that
                                                                                it is time to update
                                                                                pricing on this item otherwise it will
                                                                                automatically renew at the
                                                                                current price per our Terms and
                                                                                Conditions, which states: "All
                                                                                quoted prices auto-renew for the same
                                                                                quoted period, unless revised
                                                                                or deleted from data."</p>}</td>
                                                                    <td>{formatDateTime(p.CreatedDateTime)}</td>
                                                                </tr>
                                                            </React.Fragment>))}

                                                        {innerComm.length === 0 && (
                                                            <React.Fragment>
                                                                <tr>
                                                                    <td colSpan={3}>No System Notifications</td>
                                                                </tr>
                                                            </React.Fragment>)}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>


                            </div>


                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </section>
            </>
        );
    }

}

export default ViewRecord;

import React, { Component }  from 'react';
import axios from "axios";
import Header from "./Components/Header";
import 'react-datepicker/dist/react-datepicker.css'
import PaginatedItems from "./Components/Pagination";


export class Raws extends Component{
    state = {
        records: [],
        search: "",
        show: false,
        popupIndex: 0,
        filtered: false,
        pageNumber:1,
        totalItems: 0
    }

    componentDidMount() {
        let pageUrlValue = window.location.href.split('/')[4]
        if(pageUrlValue !== undefined) {
            const selectedOption = parseInt(pageUrlValue)
            this.setState({ selectedOption });
            this.getSuppliers(1, selectedOption)
        } else {
            this.getSuppliers()
        }

    }


    getSuppliers(pageNumber = 1, selectedOption = 0) {
        let _self = this;

        let dataObj = {
            search: _self.state.search,
            order: 'desc',
            page:pageNumber,
            filterByCompany: selectedOption
        }

        if(_self.state.search !== "") {
            const filtered = true;
            _self.setState({ filtered });
        } else {
            const filtered = false;
            _self.setState({ filtered });
        }

        axios.put(`raws/get-raws`, dataObj)
            .then(response => {
                const records = response.data.records;
                _self.setState({ records });

                const totalItems = response.data.total_records
                _self.setState({ totalItems });
            }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    onSearchClick(e)
    {
        this.getSuppliers()
    }
    

    onSearch(e)
    {
        const search = e.target.value;
        this.setState({ search })
    }

    onKeyUp (e)
    {
        if (e.charCode === 13) {
            console.log('13');
            this.onSearchClick(e);
        }
    };

    openPage(e, dataId){
        e.preventDefault();
        window.location.href = "/view-raws/"+dataId;
    }

    onAddClick(e){
        e.preventDefault();
        window.location.href = "/add-raws";
    }

    paginatePage = (page)=> {
        console.log(`pageNumber ! ${page}`)
        const pageNumber = page
        this.setState({ pageNumber });
        this.getSuppliers(page, this.state.selectedOption);
    }

    onExport(e)
    {
        let _self = this;

        let dataObj = {
            search: _self.state.search,
            order: 'desc',
            filterByCompany: _self.state.selectedOption
        }

        if(_self.state.search !== "") {
            const filtered = true;
            _self.setState({ filtered });
        } else {
            const filtered = false;
            _self.setState({ filtered });
        }

        axios.put(`raws/download-raws`, dataObj)
            .then(response => {
                const element = document.createElement("a");
                element.href = axios.defaults.baseURL + 'raws/download-raws/0';
                element.download = "download.xlsx";
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
            }).catch(error => {
            console.error('There was an error!', error);
        });
        
        // purchases/download-purchases
    }
    

    render() {
        return (
            <>
                <Header  />




                <section className="section pb-0" style={{ marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12 row noPrint" style={{padding: '13px 30px'}}>
                                <h3>MATL’s</h3>
                            </div>
                            <div className="col-lg-12 mb-12 row noPrint">

                                <div className="input-group col-lg-2 mb-3">
                                    <button className='btn btn-success' onClick={(e) => this.onAddClick(e)}
                                            style={{borderRadius: 'unset'}}>
                                        <i className={'ti-plus'}></i> Add
                                    </button>
                                </div>
                                <div className="input-group col-lg-5 mb-3">
                                    <button onClick={(e) => this.onExport(e)} className="btn btn-success"
                                            style={{zIndex: 'unset'}}><i style={{fontSize: '16px', fontWeight: 'bold'}}
                                                                         className="ti-export"></i> Export
                                    </button>
                                </div>
                                <div className="input-group col-lg-5 mb-3">
                                    <input onKeyPress={(e) => this.onKeyUp(e)} type="text" className="form-control"
                                           onChange={(e) => this.onSearch(e)}
                                           placeholder="Ingred, SKU or Material Search" aria-label="search"
                                           style={{padding: '20px'}} aria-describedby="basic-addon2"/>
                                    <div className="input-group-append">
                                        <span onClick={(e) => this.onSearchClick(e)}
                                              className="input-group-text ti-search" id="basic-addon2"></span>
                                    </div>
                                </div>

                            </div>


                            <div className="col-lg-12 mb-12" style={{overflow: "scroll"}}>
                                <table className="table table-bordered">
                                    <thead className="tableHead">
                                    <tr>
                                        <th scope="col">Edit</th>
                                        <th scope="col">ID#</th>
                                        <th scope="col">Raw Material Name</th>
                                        <th scope="col">Active/Inactive</th>
                                        <th scope="col">Identity Testing Cost</th>
                                        <th scope="col">Testing Method</th>
                                        <th scope="col">Type (Micro Limit)</th>
                                        <th scope="col">Common Name</th>
                                        <th scope="col">Botanical Name</th>
                                        <th scope="col">HOC</th>
                                        <th scope="col">Part</th>
                                        <th scope="col">Form</th>
                                        <th scope="col">Ratio</th>
                                        <th scope="col">LOD (NMT)</th>
                                        <th scope="col">LOI (NMT)</th>
                                        <th scope="col">Ingredient Declaration on Label</th>
                                        <th scope="col">Identity</th>
                                        <th scope="col">Limit</th>
                                        <th scope="col">Method</th>
                                        <th scope="col">Reference</th>
                                        <th scope="col">Testing Cost</th>
                                        <th scope="col">Active 1</th>
                                        <th scope="col">Limit 1</th>
                                        <th scope="col">Method</th>
                                        <th scope="col">Reference</th>
                                        <th scope="col">Testing Cost</th>
                                        <th scope="col">Active 2</th>
                                        <th scope="col">Limit 2</th>
                                        <th scope="col">Method</th>
                                        <th scope="col">Reference</th>
                                        <th scope="col">Testing Cost</th>
                                        <th scope="col">Appearance</th>
                                        <th scope="col">Color</th>
                                        <th scope="col">Odor</th>
                                        <th scope="col">Taste</th>
                                        <th scope="col">Bulk</th>
                                        <th scope="col">Tapped</th>
                                        <th scope="col">Mesh Size</th>
                                        <th scope="col">Arsenic</th>
                                        <th scope="col">Cadmium</th>
                                        <th scope="col">Lead</th>
                                        <th scope="col">Mercury</th>
                                        <th scope="col">TAMC</th>
                                        <th scope="col">TYMC</th>
                                        <th scope="col">Coliforms</th>
                                        <th scope="col">E. coli</th>
                                        <th scope="col">Salmonella spp.</th>
                                        <th scope="col">Staph aureus</th>
                                        <th scope="col">Absent</th>
                                        <th scope="col">Milk</th>
                                        <th scope="col">Egg</th>
                                        <th scope="col">Soy</th>
                                        <th scope="col">Fish</th>
                                        <th scope="col">Shellfish</th>
                                        <th scope="col">Tree Nuts</th>
                                        <th scope="col">Peanuts</th>
                                        <th scope="col">Wheat</th>
                                        <th scope="col">Gluten*</th>
                                        <th scope="col">Overage</th>
                                        <th scope="col">Cost</th>
                                        <th scope="col">Cost Unit</th>
                                        <th scope="col">Cost/g</th>
                                        <th scope="col">MOQ (kg)</th>
                                        <th scope="col">Amazon Testing</th>
                                        <th scope="col">Laboratory</th>
                                        <th scope="col">Solubility (g/mL)</th>

                                    </tr>
                                    </thead>
                                    <tbody>

                                    {this.state.records.filter(p => p.DataType === undefined).map((p, index) => (

                                        <React.Fragment key={index}>
                                            <tr data-toggle={`collapse`} data-target={`#row${index}`}
                                                className="clickable" style={{cursor: 'pointer'}}>
                                                <td onClick={(e) => this.openPage(e, p.RawsId)}>
                                                    <i
                                                        className="ti-pencil-alt"></i></td>
                                                <td>{p.Id}</td>
                                                <td>{p.RawMaterialName}</td>
                                                <td>{p.ActiveInactive}</td>
                                                <td>{p.IdentityTestingCost}</td>
                                                <td>{p.TestingMethod}</td>
                                                <td>{p.Type_MicroLimit}</td>
                                                <td>{p.CommonName}</td>
                                                <td>{p.BotanicalName}</td>
                                                <td>{p.HOC}</td>
                                                <td>{p.Part}</td>
                                                <td>{p.Form}</td>
                                                <td>{p.Ratio}</td>
                                                <td>{p.LOD_NMT}</td>
                                                <td>{p.LOI_NMT}</td>
                                                <td>{p.IngredientDeclarationonLabel}</td>
                                                <td>{p.Identity}</td>
                                                <td>{p.Limit0}</td>
                                                <td>{p.Limit0Method}</td>
                                                <td>{p.Limit0Reference}</td>
                                                <td>{p.Limit0TestingCost}</td>
                                                <td>{p.Active1}</td>
                                                <td>{p.Limit1}</td>
                                                <td>{p.Limit1Method}</td>
                                                <td>{p.Limit1Reference}</td>
                                                <td>{p.Limit1TestingCost}</td>
                                                <td>{p.Active2}</td>
                                                <td>{p.Limit2}</td>
                                                <td>{p.Limit2Method}</td>
                                                <td>{p.Limit2Reference}</td>
                                                <td>{p.Limit2TestingCost}</td>
                                                <td>{p.Appearance}</td>
                                                <td>{p.Color}</td>
                                                <td>{p.Odor}</td>
                                                <td>{p.Taste}</td>
                                                <td>{p.Bulk}</td>
                                                <td>{p.Tapped}</td>
                                                <td>{p.MeshSize}</td>
                                                <td>{p.Arsenic}</td>
                                                <td>{p.Cadmium}</td>
                                                <td>{p.Leadx}</td>
                                                <td>{p.Mercury}</td>
                                                <td>{p.TAMC}</td>
                                                <td>{p.TYMC}</td>
                                                <td>{p.Coliforms}</td>
                                                <td>{p.E_coli}</td>
                                                <td>{p.Salmonellaspp}</td>
                                                <td>{p.Staphaureus}</td>
                                                <td>{p.Absent}</td>
                                                <td>{p.Milk}</td>
                                                <td>{p.Egg}</td>
                                                <td>{p.Soy}</td>
                                                <td>{p.Fish}</td>
                                                <td>{p.Shellfish}</td>
                                                <td>{p.TreeNuts}</td>
                                                <td>{p.Peanuts}</td>
                                                <td>{p.Wheat}</td>
                                                <td>{p.Gluten}</td>
                                                <td>{p.Overage}</td>
                                                <td>{p.Cost}</td>
                                                <td>{p.CostUnit}</td>
                                                <td>{p.Cost_g}</td>
                                                <td>{p.MOQ_kg}</td>
                                                <td>{p.AmazonTesting}</td>
                                                <td>{p.Laboratory}</td>
                                                <td>{p.Solubility_g_mL}</td>

                                            </tr>

                                        </React.Fragment>))}


                                    {
                                        this.state.records.length === 0 ? (<tr>
                                            <td colSpan="10" style={{textAlign: 'center'}}>No Records</td>
                                        </tr>) : (<tr></tr>)
                                    }


                                    </tbody>
                                </table>
                             

                            </div>
                            <div className="col-lg-12 mb-12">
                                <PaginatedItems total={this.state.totalItems}
                                                onPageChange={this.paginatePage}></PaginatedItems>
                            </div>


                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                </section>


            </>
        );
    }
}

export default Raws;

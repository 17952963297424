import ApexLogo from '../apex-logo.png';
import React, { Component }  from 'react';

export class Header extends Component {
    state = {

    }

    componentDidMount() {

    }

    logout(e) {
        e.preventDefault();
        localStorage.removeItem('token');
        window.location.href = "/login";
    }
    render() {
        return (
            <header className="navigation fixed-top">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-white">
                        <a className="navbar-brand order-1" href="/suppliers-data">
                            {/*<img className="img-fluid" width="100px" src={Logo} alt="Peak"/>*/}
                            <img className="img-fluid" width="100px" src={ApexLogo} alt="APEX"/>
                        </a>
                        <div className="collapse navbar-collapse text-center order-lg-2 order-3" id="navigation">
                            <ul className="navbar-nav mx-auto">
                                <li className="nav-item menu-border">
                                    <a className="nav-link" href="/suppliers-data"> <i
                                        className={'ti-truck'}></i> Suppliers</a>
                                </li>

                                <li className="nav-item menu-border">
                                    <a className="nav-link" href="/purchases"> <i
                                        className={'ti-shopping-cart'}></i> Purchases</a>
                                </li>

                                <li className="nav-item menu-border">
                                    <a className="nav-link" href="/raws"> <i
                                        className={'ti-package'}></i> MATL’s</a>
                                </li>

                                <li className="nav-item menu-border">
                                    <a className="nav-link" target={'_blank'} rel={'noreferrer'} href="http://98.83.67.127:8080/flinkiso/users/login"> <i
                                        className={'ti-package'}></i> APEX-QMS</a>
                                </li>

                                {/*<li className="nav-item menu-border">*/}
                                {/*    <a className="nav-link" href="/dn-reports"> <i*/}
                                {/*        className={'ti-bar-chart'}></i> DN Reports</a>*/}
                                {/*</li>*/}

                                {/*<li className="nav-item menu-border">*/}
                                {/*    <a className="nav-link" href="/home"><i className={'ti-home'}></i> SOW</a>*/}
                                {/*</li>*/}


                                {/*<li className="nav-item menu-border">*/}
                                {/*    <a className="nav-link" href="/upload"> <i className={'ti-upload'}></i> Upload</a>*/}
                                {/*</li>*/}

                            </ul>
                        </div>

                        <div className="order-2 order-lg-3 d-flex align-items-center">
                            <button onClick={(event) => {
                                this.logout(event)
                            }} className="border-0 order-1" type="button" data-toggle="collapse" data-target="#navigation" style={{background:'none'}}>
                                <i className="ti-user"></i> Logout
                            </button>
                        </div>

                    </nav>
                </div>
            </header>
        )
    }
}

    export default Header;
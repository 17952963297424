import React, { Component }  from 'react';
import axios from "axios";
import Header from "./Components/Header";
import 'react-datepicker/dist/react-datepicker.css'
import PaginatedItems from "./Components/Pagination";


export class DnReports extends Component{
    state = {
        records: [],
        search: "",
        show: false,
        popupIndex: 0,
        filtered: false,
        pageNumber:1,
        totalItems: 0
    }

    componentDidMount() {
        let pageUrlValue = window.location.href.split('/')[4]
        if(pageUrlValue !== undefined) {
            const selectedOption = parseInt(pageUrlValue)
            this.setState({ selectedOption });
            this.getSuppliers(1, selectedOption)
        } else {
            this.getSuppliers()
        }

    }


    getSuppliers(pageNumber = 1, selectedOption = 0) {
        let _self = this;

        let dataObj = {
            search: _self.state.search,
            order: 'desc',
            page:pageNumber,
            filterByCompany: selectedOption
        }

        if(_self.state.search !== "") {
            const filtered = true;
            _self.setState({ filtered });
        } else {
            const filtered = false;
            _self.setState({ filtered });
        }

        axios.put(`raws/get-raws`, dataObj)
            .then(response => {
                const records = response.data.records;
                _self.setState({ records });

                const totalItems = response.data.total_records
                _self.setState({ totalItems });
            }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    onSearchClick(e)
    {
        this.getSuppliers()
    }


    onSearch(e)
    {
        const search = e.target.value;
        this.setState({ search })
    }

    onKeyUp (e)
    {
        if (e.charCode === 13) {
            console.log('13');
            this.onSearchClick(e);
        }
    };

    openPage(e, dataId){
        e.preventDefault();
        window.location.href = "/view-raws/"+dataId;
    }

    onAddClick(e){
        e.preventDefault();
        window.location.href = "/add-raws";
    }

    paginatePage = (page)=> {
        console.log(`pageNumber ! ${page}`)
        const pageNumber = page
        this.setState({ pageNumber });
        this.getSuppliers(page, this.state.selectedOption);
    }


    render() {
        return (
            <>
                <Header  />




                <section className="section pb-0" style={{ marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12 row noPrint" style={{padding: '13px 30px'}}>
                               
                            </div>
                            <div className="col-lg-12 mb-12 row noPrint">

                                <div className="input-group col-lg-7 mb-3">

                                </div>
                                <div className="input-group col-lg-5 mb-3">
                                    <input onKeyPress={(e) => this.onKeyUp(e)} type="text" className="form-control"
                                           onChange={(e) => this.onSearch(e)}
                                           placeholder="Ingred, SKU or Material Search" aria-label="search"
                                           style={{padding: '20px'}} aria-describedby="basic-addon2"/>
                                    <div className="input-group-append">
                                        <span onClick={(e) => this.onSearchClick(e)}
                                              className="input-group-text ti-search" id="basic-addon2"></span>
                                    </div>
                                </div>

                            </div>


                            <div className="col-lg-12 mb-12" style={{overflow: "scroll"}}>
                                <table className="table table-bordered">
                                    <thead className="tableHead">
                                    <tr>
                                        <th scope="col">PO#</th>
                                        <th scope="col">Order Received</th>
                                        <th scope="col">How much</th>
                                        <th scope="col">Expected</th>
                                        <th scope="col">Supplier</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {this.state.records.filter(p => p.DataType === undefined).map((p, index) => (

                                        <React.Fragment key={index}>
                                            <tr data-toggle={`collapse`} data-target={`#row${index}`}
                                                className="clickable" style={{cursor: 'pointer'}}>

                                                <td>{'30041'}</td>
                                                <td>{'24/01/2025'}</td>
                                                <td>{'$142,000.00'}</td>
                                                <td>{'14/02/2025'}</td>
                                                <td>{'NINGBO Herb'}</td>

                                            </tr>
                                            
                                            <tr>
                                                <td colSpan={5}>
                                                    <table className="table table-bordered">
                                                        <thead className="tableHead">
                                                        <tr>
                                                            <th scope="col">Part#</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Description</th>
                                                            <th scope="col">Status</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>{'60368'}</td>
                                                            <td>{'Turmeric Root 95% Curcuminoids'}</td>
                                                            <td>{'Turmeric Root 95% Curcuminoids'}</td>
                                                            <td>
                                                                <ol className="progress-bar-xx">
                                                                    <li className="is-complete">
                                                                        <span>Objective &amp; Template</span></li>
                                                                    <li className="is-complete">
                                                                        <span>Order Received</span></li>
                                                                    <li className="is-active"><span>Quarantine</span></li>
                                                                    <li><span>Warehouse</span></li>
                                                                    <li><span>Pick</span></li>
                                                                    <li><span>Pharmacy</span></li>
                                                                    <li><span>Production BOM</span></li>
                                                                    <li><span>Packaging BOM</span></li>
                                                                    <li><span>Shipment BOM</span></li>
                                                                </ol>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{'61030'}</td>
                                                            <td>{'Organic Turmeric Root Powder'}</td>
                                                            <td>{'Organic Turmeric Root Powder'}</td>
                                                            <td>
                                                                <ol className="progress-bar-xx">
                                                                    <li className="is-complete">
                                                                        <span>Objective &amp; Template</span></li>
                                                                    <li className="is-active">
                                                                        <span>Order Received</span></li>
                                                                    <li><span>Quarantine</span></li>
                                                                    <li><span>Warehouse</span></li>
                                                                    <li><span>Pick</span></li>
                                                                    <li><span>Pharmacy</span></li>
                                                                    <li><span>Production BOM</span></li>
                                                                    <li><span>Packaging BOM</span></li>
                                                                    <li><span>Shipment BOM</span></li>
                                                                </ol>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>

                                                </td>
                                            </tr>

                                        </React.Fragment>))}


                                    {
                                        this.state.records.length === 0 ? (<tr>
                                            <td colSpan="10" style={{textAlign: 'center'}}>No Records</td>
                                        </tr>) : (<tr></tr>)
                                    }


                                    </tbody>
                                </table>


                            </div>
                            <div className="col-lg-12 mb-12">
                                <PaginatedItems total={this.state.totalItems}
                                                onPageChange={this.paginatePage}></PaginatedItems>
                            </div>


                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                </section>


            </>
        );
    }
}

export default DnReports;
